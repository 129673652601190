// views/pages/SamlCallback.js
import React, { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from "hooks/useAuth";

const SamlCallback = () => {
    const history = useHistory();
    const location = useLocation();
    const { setAuth, setSsoError, parseJwt } = useAuth();

    const { REACT_APP_API_URL } = process.env;
    let url = REACT_APP_API_URL;

    let tkn;

    useEffect(() => {
        const tokenMatch = document.cookie.match(/(?:^|; )auth_token=([^;]*)/);
        tkn = tokenMatch ? decodeURIComponent(tokenMatch[1]) : null;
        
        if (tkn) {
            verifySsoToken(parseJwt(tkn).token_jwt);
        } else {
            const error = location.pathname.split("/")[3];

            if (error){
                setSsoError(error)
                history.push('/auth/login');
            } else {
                setSsoError("500")
                history.push('/auth/login');
            }
        }
    }, [history, location]);

    const verifySsoToken = async (token) => {
        let endpoint = url +"users/token_verification";
        fetch(endpoint, {
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({accessToken: token}),
            method: "POST",
        }).then((res) => {
            if(res.ok){ 
                // Obtener el dominio raíz dinámicamente
                const hostnameParts = window.location.hostname.split('.');
                const rootDomain = hostnameParts.slice(-2).join('.');

                //Delete cookie
                document.cookie =`auth_token=; domain=${rootDomain}; Max-Age=0; path=/;`;

                //Provide access
                localStorage.setItem("token_hensall", JSON.stringify(tkn));
                setAuth(true);
                history.push('/admin/app/'); 
            } else {
                const error = location.pathname.split("/")[3];

                if (error){
                    setSsoError(error)
                    history.push('/auth/login');
                }else {
                    setSsoError("500")
                    history.push('/auth/login');
                }    
            }
        }).catch((err) => {
            const error = location.pathname.split("/")[3];

            if (error){
                setSsoError(error)
                history.push('/auth/login');
            }
        });
    }

    return (<></>);
};

export default SamlCallback;