/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: AppContext.js
# Description: This is the context provider of the Home applicaton cards. Containig logic, API calls, and variables needed.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/26/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 03/01/2023

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createContext, useContext, useEffect, useState } from "react";
import { helpHttp } from "helpers/helpHttp";
import LoadingContext from "context/LoadingContext";
import NotificationContext from "context/NotificationContext";
import { useHistory } from "react-router";

import { useAuth } from "hooks/useAuth";
// import { decodeToken } from "react-jwt";

const AppContext = createContext();

const AppProvider = ({children}) => {

    const { checkTokenExpiryApp, getIdToken, parseJwt, getCurrentUserApp, getNameUser, getRefreshToken, setAuth } = useAuth();
    const { setMessage, setStatus, setType } = useContext(NotificationContext);

    const { REACT_APP_API_URL, REACT_APP_FIELDTRACE_API_URL } = process.env;

    const history = useHistory();
    const [ users, setUsers ] = useState([]);
    const [ systems, setSystems] = useState([]);
    const [ tokenFieldTrace, setTokenFieldTrace ] = useState();
    const [ urlFieldTrace, setUrlFieldTrace ] = useState();
    const [ structureFieldTrace, setStructureFieldTrace ] = useState();
    

    const [viewModalUsers, setViewModalUsers] = useState(false);
    const [viewModalAddUsers, setViewModalAddUsers] = useState(false);

    const { setLoading } = useContext(LoadingContext);

    let api = helpHttp();
    let url = REACT_APP_API_URL;

    useEffect(() => {
        checkTokenExpiryApp();
    },[]);

    const selectApp = (type, acronym, token, url, structure) => {
        checkTokenExpiryApp();
        
        if(type === "JWT"){

            let tokenDecode = parseJwt(token);
            let token_jwt = tokenDecode.token_jwt;
            
            // Obtener el dominio raíz dinámicamente
            const hostnameParts = window.location.hostname.split('.');
            const rootDomain = hostnameParts.slice(-2).join('.');

            // Validar si 'acronym' tiene un valor; en caso contrario, asignar un valor predeterminado
            const cookieName = acronym ? `${acronym}_temporal_token` : "default_temporal_token";
    
            // Verificar si la cookie ya está configurada con el valor actual
            const existingCookie = document.cookie.split('; ').find(row => row.startsWith(cookieName));

            // Configurar la cookie si aún no existe o si ha cambiado
            if (!existingCookie || !existingCookie.includes(token_jwt)) {
                document.cookie = `${cookieName}=${token_jwt}; domain=${rootDomain}; path=/; Secure; SameSite=None;`;
            }
            if(acronym === "hlogin"){    
                history.push("init/"+acronym+"?token="+token_jwt);
            } else if (acronym === "contracts" || acronym === "dispatch" || acronym === "mantables" ) {
                window.location.replace(url+"/auth/init/"+acronym+"?token="+token_jwt);
            } else {
                window.location.replace(url+"/auth/init/"+acronym);
            }
        }
        else {            
            if(acronym === "fieldtrace"){
                const usernames = usersToken(token);
                if (usernames) {
                    setUsers(usersToken(token));
                    setTokenFieldTrace(token);    
                    setUrlFieldTrace(url);
                    setStructureFieldTrace(structure)
                } else {
                    setViewModalAddUsers(true)
                }
            } else if(acronym === "SageMem"){ 
                window.location.replace(url);
            } else{
                init(acronym, token, url, structure);
            }    
        }
    };

    const init = (acronym, token, url, structure) => {
        setLoading(true);
        let tokenDecode = parseJwt(token);
        let accesToken = tokenDecode.token.AuthenticationResult.AccessToken;
        if (acronym === "cis") {
            window.open(url+structure+accesToken, "_blank");
            setLoading(false);
        } else {
            window.location.replace(url+structure+accesToken);
        }
        
    };

    const usersToken = (token) => {
        let tokenDecode = parseJwt(token);
        let idToken = tokenDecode.token.AuthenticationResult.IdToken;
        let userIdToken = parseJwt(idToken);
        let usernames = userIdToken['custom:FT-username'];

        if (!usernames) {return}

        usernames = usernames.replace(/['"]+/g, '');
        usernames = usernames.replace("[", '');
        usernames = usernames.replace("]", '');
        return usernames.split('|');
    }

    const initFieldTrace = (username) => {
        let tokenDecode = parseJwt(tokenFieldTrace);
        let emailToken = tokenDecode.sub;
        let accesToken = tokenDecode.token.AuthenticationResult.AccessToken;
        window.open(urlFieldTrace+structureFieldTrace+accesToken+"&userName="+username+"&email="+emailToken, "_blank");
    }

    // Token refresh
    const refreshAuthenticated = (token) => {
        localStorage.setItem("token_hensall", JSON.stringify(token));
        history.go(0)
        setAuth(true);
    }

    const syncFieldTrace = ({username, password}) => {
        setLoading(true);
        let urlFieldTrace = REACT_APP_FIELDTRACE_API_URL;
        
        let options = {
            body: {
                username: username,
                password: password
            },
            headers: {"content-type":"application/json"},
        }
        api.post(urlFieldTrace, options).then((res) => {
            if(res.token){
                updateAttribute({username: username})
            }else{
                setLoading(false)
                setType("danger");
                setMessage("You had incorrectly typed your email or password. Please, correct them and try again.");
                setStatus(1);
            }
        }).catch((err) => {
            if (err) {
                setLoading(false)
                setType("danger");
                setMessage("You had incorrectly typed your email or password. Please, correct them and try again.");
                setStatus(1);
            }
        })
    }

    const updateAttribute = (data) => {
        setLoading(true);
        
        const id = getIdToken()
        let currentList = id['custom:FT-username'];

        if (currentList){
            currentList = currentList.replaceAll("|", ",")
            currentList = JSON.parse(currentList)

            if (!currentList.includes(data.username)) {
                currentList.push(data.username);
            } else {
                setLoading(false)
                setType("info");
                setMessage("The user you try to add is already saved");
                setStatus(1);
                return
            }

            let username = JSON.stringify(currentList)
            username = username.replaceAll(",", "|")
            data.attributes = "custom:FT-username;" + username
        } else {
            data.attributes = "custom:FT-username;[\"" + data.username + "\"]";
        }

        data.email = getNameUser()
        
        let endpoint = url +"users/update_attribute";

        let options = {
            body: data,
            headers: {"content-type":"application/json"},
        }

        api.post(endpoint, options).then((res) => {
            if(!res.err){
                if(res.ResponseMetadata.HTTPStatusCode == 200){
                    refreshToken()
                    setType("success");
                    setMessage("New FieldTrace username saved");
                    setStatus(1);
                }
            }else{
                setLoading(false);
                setType("danger");
                setMessage("Currently the service is presenting interruptions. Try again later, if error persists please send an email to HensallLoginSupport@hdc.on.ca with subject: 'Service interruption Error OL-03'.");
                setStatus(1);
            }
            return false;
        })
    }

    // Refresh Token
    const refreshToken = () => {
        const refresh = getRefreshToken()
        const email = getNameUser();

        if (!refresh) {
            setLoading(false)
            setType("danger");
            setMessage("The data was already updated. For you to see the form changes, please, log out and in to the app again.");
            setStatus(1);
           return
        }

        let endpoint = url +"auth/refresh_token";
        let options = {
                body: {
                    email: email,
                    refresh_token: refresh
                },
            headers: {"content-type":"application/json"},
            login: true
        }
        api.post(endpoint, options).then((res) => {
            if(!res.err){
                setLoading(false)
                refreshAuthenticated(res.access_token)
            }else{
                setLoading(false)
                setType("danger");
                setMessage("The data was already updated. For you to see the form changes, please, log out and in to the app again.");
                setStatus(1);
            }
        }).catch((err) => {
            if (err) {
                setLoading(false)
                setType("danger");
                setMessage("The data was already updated. For you to see the form changes, please, log out and in to the app again.");
                setStatus(1);
            }
        })
    }

    // Refresh Application list
    const fetchApplicationList = () => {
        let endpoint = url +"users/user_systems";
        let options = {
            headers: {"content-type":"application/json"},
            bearer: parseJwt(getCurrentUserApp()).token_jwt
        }
        api.get(endpoint, options).then((res) => {
            if (String(systems) == String(res)) {
                return;
            } else { 
                if (res.length > 0) {
                    setSystems(res);
                }
            }
        }).catch((err) => {})
    }

    const data = { selectApp, users, systems, setSystems, viewModalUsers, setViewModalUsers, viewModalAddUsers, setViewModalAddUsers, initFieldTrace, syncFieldTrace, fetchApplicationList };

    return <AppContext.Provider value={data}>{children}</AppContext.Provider>;
}

export { AppProvider };
export default AppContext;
