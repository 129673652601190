/*
=========================================================
* PRISMA DIGITAL LLC development for Hensall Co-op
=========================================================

* This project code was developed by Prisma Digital Development team based on a React template

# File name: Init.jsx
# Description: This component is executed to initiate the UAM, only contains some functionality and loading.
# Created by: Yan Carlo Angarita Sanguino
# Creation Date: 10/28/2022

# Last Modification By: Juan David Olivares Padilla
# Last Modification Date: 12/07/2022

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import InitContext from "context/InitContext";
import Loading from "components/Loading/Loading.js";
import LoadingContext from "context/LoadingContext";

function Init() {

  const { loading, setLoading } = useContext(LoadingContext);
  const [ token, setToken] = useState();
  
  const history = useHistory();
  const location = useLocation();

  const { init } = useContext(InitContext);

  useEffect(() => {
    setLoading(true);
    const tokenMatch = document.cookie.match(/(?:^|; )hlogin_temporal_token=([^;]*)/);
    const singleValue = tokenMatch ? decodeURIComponent(tokenMatch[1]) : null;
    // const queryParams = new URLSearchParams(location.search);
    // const singleValue = queryParams.get('token');

    // Obtener el dominio raíz dinámicamente
    const hostnameParts = window.location.hostname.split('.');
    const rootDomain = hostnameParts.slice(-2).join('.');

    if(singleValue){
      document.cookie =`hlogin_temporal_token=; domain=${rootDomain}; Max-Age=0; path=/;`;
      setToken(singleValue);
    }else{
      history.push('/auth/login');
    }
  },[]);

  useEffect(() => {
    if(token){
      init(token);
    }
  },[token]);

  return (
    <>
      {loading ? <Loading />:""}
      <div className="header bg-gradient-hensall">
      </div>
    </>
  );
}

export default Init;